import React, { Component } from 'react';
import PropTypes from "prop-types";

const restCallController = (WrappedComponent) => {

  class HigherOrderComponent extends Component {

    PropTypes = {
      successCallback: PropTypes.func.isRequired,
      failureCallback: PropTypes.func.isRequired
    };

    constructor(props) {
      super(props);
      this.state = {
        isFetching: false,
        errors: {}
      };
    }

    toggleIsFetching = () => {
      this.setState((prevState) => {
        return { isFetching: !prevState.isFetching }
      })
    };

    handlePromise = (promise) => {
      let { successCallback, failureCallback } = this.props;
      let { toggleIsFetching, handleError } = this;
      toggleIsFetching();
      return (promise.then(json => {
        console.log("got response inside restCall controller====", json);
        toggleIsFetching();
        if (json.data.header.statusCode === 200 && json.data.header.errorCode === 0) {
          successCallback(json);
        }
        else if (json.data.header.statusCode === 404 && json.data.header.errorCode === 0) {
          throw (json.data.header);
        }
        else if (json.data.header.statusCode === 400 && json.data.header.errorCode === 0) {
          throw (json.data.header);
        }
        else if (json.data.header.statusCode === 204 && json.data.header.errorCode === 0) {
          throw (json.data.header);
        }
        else if (json.data.header.statusCode === 409 && json.data.header.errorCode === 0) {
          throw (json.data.header);
        }
        return json;
      })
        .catch(error => {
          console.log("error", error);
          toggleIsFetching();
          handleError(error);
          this.state.isFetching && toggleIsFetching();
          failureCallback(error);
        })
      );
    };

    handleError = (error) => {
      console.log("error", error)
      let errors = error;
      this.setState({
        errors: errors
      });
    }

    render() {

      return (<WrappedComponent {...this.props} isFetching={this.state.isFetching} handlePromise={this.handlePromise}
        errors={this.state.errors} handleError={this.handleError} />)
    }
  }
  return HigherOrderComponent;
}

export default restCallController;


