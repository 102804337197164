import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import indexRoutes from "./indexRoutes/indexRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from "./auth/containers/LoginPage";

import GlobalErrorBoundary from "./common/components/error_boundaries/GlobalErrorBoundary";

function App() {
  return (
    <div className="App">
      <GlobalErrorBoundary>
      <ToastContainer />
      <Suspense fallback={<div>loading</div>}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route
                path={prop.path}
                component={prop.component}
                exact={prop.exact}
                key={key}
                onEnter={prop.onEnter}
              />
            );
          })}
          {/* <Route path="*" render={() => <Redirect to='/auth/login' />}/> */}
          {/* <Route path='*' exact={false} component={LoginPage}/> */}
          <Redirect to="/auth/login" />
        </Switch>
      </Suspense>
      </GlobalErrorBoundary>
    </div>
  );
}

export default App;
