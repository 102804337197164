import axios from "axios";
import CryptoJS, { enc } from "crypto-js";

export const login = (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_API_ENDPOINT}/login-admin`, data)
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};

export const sendOtp = (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_API_ENDPOINT}/forgot-password`, data)
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};

export const verifyOtp = (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_API_ENDPOINT}/forgot-password/verify`,
      data
    )
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};

export const resetPassword = (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_API_ENDPOINT}/forgot-password/verify/reset`,
      data
    )
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};

export const getUserProfile = (authToken) => {
  let config = { headers: { authToken: authToken } };
  return axios
    .get(
      `${process.env.REACT_APP_BASE_API_ENDPOINT}/user/get-user-profile`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const encryptData = (data, secretKey) => {
  const stringifiedData =
    typeof data === "string" ? data : JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(stringifiedData, secretKey).toString();
  return encrypted;
};

export const decryptData = (cipherText, secretKey, type) => {
  let text = type === "string" ? cipherText : cipherText.slice(1, -1);

  try {
    const bytes = CryptoJS.AES.decrypt(text, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return type === "string" ? decrypted : JSON.parse(decrypted);
  } catch (e) {
    console.error("Decryption error:", e);
    return null;
  }
};
