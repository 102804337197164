import React, { Component } from "react";
import { toast } from 'react-toastify';

 class GlobalErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }


    static getDerivedStateFromError(error) {
        return { hasError: true };
      }
  
    // gets triggered when there is any error in child tree. 
    componentDidCatch(error, info) {
      this.setState({ hasError: true });
    }

    // renders fallback page if there is any error in subtree.
    renderFallbackPage=()=>{

    //     toast.error("Something went wrong.Please login again", {
    //         position: toast.POSITION.TOP_RIGHT
    //     })
    //   setTimeout(() => {
    //     window.location = '/auth/login'
    //   }, 4000);  
    window.location = '/auth/login'
    }
  
    render() {
      if (this.state.hasError) {
        return this.renderFallbackPage();
      }
      return this.props.children;
    }
  }


  export default GlobalErrorBoundary