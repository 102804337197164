import axios from 'axios';
import { toast } from 'react-toastify';


export const responseInterceptor=()=>
{
    axios.interceptors.response.use(response => {
        let { data: { header = {} } = {} } = response;
        if (header.statusCode === 401) {
            toast.error(header.message, {
                position: toast.POSITION.TOP_RIGHT
            })
          setTimeout(() => {
            window.location = '/auth/login'
          }, 4000);  
        }
        return response;
    })
}