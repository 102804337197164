import React, { Component} from 'react';  
import PropTypes from "prop-types";

const formController=(WrappedComponent)=>{           

class NewComponent extends Component      
{                     
    

    PropTypes = {
        onModelChange: PropTypes.func.isRequired,
        model: PropTypes.object.isRequired
      };
  
      /**
       * Constructor
       *
       * @param props
       */
      constructor(props) {
        super(props);
      }
  
      
      onFieldChange = (event) => {
        this.props.onModelChange(event.target.name, event.target.value);
      };
  
      /**
       * Render wrapped component
       *
       * @return {*}
       */
      render() {
        return <WrappedComponent {...this.props} onModelChange={this.onFieldChange}/>;
      }                                                  
}                        
return NewComponent;      
}                            

export default formController;              