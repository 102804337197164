import { createTheme } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';

const theme = createTheme({
  palette: {
    secondary: {
      main: lightBlue[600],
    },
  },
});

export default theme;