import secureLocalStorage from "react-secure-storage";
import { decryptData, encryptData } from "./auth/services/AuthService";

export default class Utils {
  static getAuthToken = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      return this.getAuthTokenFromLocalStorage();
    return "";
  };

  static getAuthTokenFromLocalStorage = () => {
    const token = secureLocalStorage.getItem(
      process.env.REACT_APP_AUTH_TOKEN_KEY
    );
    if (!token) {this.logoutUser(); return ""}
    return token;
  };

  static setAuthToken = (authToken) => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
    this.setAuthTokenToLocalStorage(authToken);
  };

  static setAuthTokenToLocalStorage = (authToken) => {
    secureLocalStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_KEY, authToken);
  };

  static getUser = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      return Utils.getUserFromLocalStorage();
    return {};
  };

  static setUser = (user) => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      this.setUserToLocalStorage(user);
  };

  static getUserFromLocalStorage = () => {
    const user = secureLocalStorage.getItem(process.env.REACT_APP_AUTH_USER);
    console.log(user, "User")
    if (!user)  {
     this.logoutUser();
      return {};
    };
    return user;
  };

  static setUserToLocalStorage = (user) => {
    secureLocalStorage.setItem(process.env.REACT_APP_AUTH_USER, user);
  };

  static getLoginTime = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      return this.getLoginTimeFromLocalStorage();
    return null;
  };

  static setLoginTime = (date) => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      this.setLoginTimeToLocalStorage(date);
  };

  static setLoginTimeToLocalStorage = (date) => {
    secureLocalStorage.setItem(process.env.REACT_APP_AUTH_LOGIN_TIME, date);
  };
  static getTimeStamp = (timestamp) => {
    const date = new Date(timestamp.slice(1, -1));

    // Convert to the desired time zone (India Standard Time)
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Kolkata",
      timeZoneName: "short",
    };

    return date.toUTCString("en-US", options);
  };

  static getLoginTimeFromLocalStorage = () => {
    const loginTime = secureLocalStorage.getItem(
      process.env.REACT_APP_AUTH_LOGIN_TIME
    );
    if (!loginTime) {this.logoutUser(); return null};
    return loginTime;
  };

  static logoutUser = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      this.logoutFromLocalStorage();
  };

  static logoutFromLocalStorage = () => {
    secureLocalStorage.clear();
  };

  static setCompanyId = (data) => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      this.setCompanyIdToLocalStorage(data);
  };

  static setCompanyIdToLocalStorage = (data) => {
    secureLocalStorage.setItem(process.env.REACT_APP_AUTH_COMPANY, data);
  };
  static getCompanyIdFromLocalStorage = () => {
    const companyid = secureLocalStorage.getItem(
      process.env.REACT_APP_AUTH_COMPANY
    );


    if (!companyid) {  return {}};
    return companyid;
  };
  static getCompanyId = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      return this.getCompanyIdFromLocalStorage();
    return "";
  };

  static getUserDetails = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      return Utils.getUserDetailsFromLocalStorage();
    return {};
  };

  static setUserDetails = (user) => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === "local_storage")
      this.setUserDetailsToLocalStorage(user);
  };

  static getUserDetailsFromLocalStorage = () => {
    const user = secureLocalStorage.getItem(
      process.env.REACT_APP_AUTH_USER_DETAILS
    );
    if (!user) {this.logoutUser(); return {}};
    return user;
  };

  static setUserDetailsToLocalStorage = (user) => {
    secureLocalStorage.setItem(process.env.REACT_APP_AUTH_USER_DETAILS, user);
  };
}
