import React from "react";
const AuthApp = React.lazy(() => import("../auth/AuthApp"));
const DashboardApp = React.lazy(() => import("../dashboards/DashboardApp"));
const SplashApp = React.lazy(() => import("../SplashApp"));
const ReportApp = React.lazy(() => import("../reports/ReportsApp"));
const PlanningApp = React.lazy(() => import("../planning/PlanningApp"));
const AdminApp = React.lazy(() => import("../admin/AdminApp"));
const OrderApp = React.lazy(() => import("../orders/OrderApp"));
const InventoryApp = React.lazy(() => import("../inventory/InventoryApp"));

const PrintingModule = React.lazy(() => import("../printer/PrinterApp"));
const FutureInventoryApp = React.lazy(() =>
  import("../futureInventory/FutureInventoryApp")
);

let indexRoutes = [
  {
    path: "/",
    name: "Splash page",
    component: SplashApp,
    exact: true,
  },
  {
    path: "/auth",
    name: "Auth app",
    component: AuthApp,
    exact: false,
  },
  {
    path: "/dashboard",
    name: "DashBoard app",
    component: DashboardApp,
    exact: false,
  },
  {
    path: "/report",
    name: "Reports app",
    component: ReportApp,
    exact: false,
  },
  {
    path: "/planning",
    name: "Planning App",
    component: PlanningApp,
    exact: false,
  },
  {
    path: "/admin",
    name: "Admin app",
    component: AdminApp,
    exact: false,
  },
  {
    path: "/order",
    name: "Order app",
    component: OrderApp,
    exact: false,
  },
  {
    path: "/inventory",
    name: "Inventory app",
    component: InventoryApp,
    exact: false,
  },
  {
    path: "/print",
    name: "Printing Module",
    component: PrintingModule,
    exact: false,
  },
  // {
  //   path: "/future_inventory",
  //   name: "Future Inventory",
  //   component: FutureInventoryApp,
  //   exact: false,
  // },
];

export default indexRoutes;
