import React, { Component } from 'react';
import formController from '../../../common/hoc/forms/FormController'

class LoginForm extends Component {
  

  render() {
    let { model, errors, onModelChange,validator } = this.props;
    return (
      <div>
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Username" name="userEmail"
            onChange={onModelChange}/>
         <small> <b className="text-danger">{validator.message('username', model.userEmail, 'required')}</b></small>

        </div>
        <div className="form-group">
          <input type="password" className="form-control" placeholder="Password" name="password"
            onChange={onModelChange} />
            <small> <b className="text-danger">{validator.message('password', model.password, 'required')}</b></small>
        </div>
        {
          errors.message &&
          <div className="form-group">
            <small className="form-text text-danger">
              {errors.message}
            </small>
          </div>
        }
      </div>);
  }
}

export default formController(LoginForm)