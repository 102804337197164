import Auth from '../../../models/AuthModel'
import SimpleReactValidator from 'simple-react-validator';

export default class AuthFormHandler {
    model = new Auth({});
    component;
  
    /**
     * Constructor
     *
     * @param component
     * @param model
     */
    constructor(component, model = new Auth({})) {
      this.model = new Auth(model);
      this.validator = new SimpleReactValidator();
      this.component = component;
      this.onModelChange = this.onModelChange.bind(this);
    }
  
    /**
     * On model field change
     *
     * @return {Function}
     * @param field
     * @param value
     */
    onModelChange(field, value) {
      this.model[field] = value;
      this.validator.showMessageFor(field);
      this.updateModel();
    };
  
    /**
     * Update model
     */
    updateModel() {
      this.component.setState({
        ...this.component.state,
        auth: this.model
      })
    }
  }