export default class AuthModel {
  userEmail = "";
    username = "";
    password = "";
    confirm_password = "";
    otp = "";
    new_password = "";
    confirm_new_password = "";
    appType=3;
  
    constructor(info) {
      this.userEmail = info.userEmail;
      this.username = info.username;
      this.password = info.password;
      this.confirm_password = info.confirm_password;
      this.otp = info.otp;
      this.new_password = info.new_password;
      this.confirm_new_password = info.confirm_new_password;
      this.appType=this.appType;
    }
  
    /**
     * To Json
     *
     * @return {{username: string, password: string}}
     */
    toJson() {
      return {
        userEmail: this.userEmail,
        username: this.username,
        password: this.password,
        confirm_password: this.confirm_password,
        otp: this.otp,
        new_password: this.new_password,
        confirm_new_password: this.confirm_new_password,
        appType:this.appType
      }
    }
  }