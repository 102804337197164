import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { responseInterceptor } from "../src/AxiosInterceptor";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import {
  applyPolyfills,
  defineCustomElements,
} from "@goapptiv-code/bluedart-tracking-web-component/loader";

import { ThemeProvider,  } from '@material-ui/core/styles';
import theme from "./custom-theme";


responseInterceptor();

applyPolyfills().then(() => {
  defineCustomElements();
});

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
    <App />{" "}
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
