import React, { Component } from "react";
import LoginFormComponent from "../components/LoginFormComponent";
import Utils from "../../Utils";
import { getUserProfile } from "../services/AuthService";

class LoginPage extends Component {
  loginSuccessCallback = (json) => {
    console.log("loggedSuccessfully");
    Utils.setAuthToken(json.data.payload.authToken);
    Utils.setUser(json.data.payload);
    Utils.setLoginTime(new Date());
    getUserProfile(json.data.payload.authToken).then((response) => {
      let {
        payload: {
          userProfileDetails: {
            _id: userId,
            adminFirstName,
            adminLastName,
            wallet = {},
            sapFormatForOrderProcessing,
            depotName
          } = {},
        } = {},
      } = response;

      if (Boolean(userId)) {
        Utils.setUserDetails({
          id: userId,
          userName: adminFirstName + " " + adminLastName,
          walletToken: wallet ? wallet.walletToken : null,
          sapFormatForOrderProcessing: sapFormatForOrderProcessing,
          depotName: depotName
        });
      }
      this.props.history.push("/");
    });
  };

  componentWillMount() {
    Utils.logoutUser();
  }

  render() {
    return (
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 offset-md-4 offset-lg-4 offset-sm-3">
        <div className="card mt-5">
          <div className="card-header bg-transparent">
            <h6 className="text-center">CONNECTOD LOGIN</h6>
            {/* <img src={Logo} style={{width:"10"}}/> */}
          </div>
        </div>
        <LoginFormComponent
          successCallback={this.loginSuccessCallback}
          failureCallback={() => {}}
        />
      </div>
    );
  }
}

export default LoginPage;
