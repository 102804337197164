import React, { Component } from 'react';
import LoginForm from './forms/LoginForm';
import { NavLink } from 'react-router-dom';
import AuthModel from '../models/AuthModel'
import { login } from "../services/AuthService";
import restCallController from '../../common/hoc/forms/RestCallController'
import AuthFormHandler from './forms/handlers/AuthFormHandler'
import { sha256 } from 'js-sha256';

class LoginFormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      auth: new AuthModel({})
    };
    this.authFormHandler = new AuthFormHandler(this, this.state.auth);
  }

  submitLoginForm = () => {
    let { handlePromise } = this.props;
    let { userEmail, password } = this.state.auth;
    if (Boolean(userEmail) && Boolean(userEmail.trim()) && this.authFormHandler.validator.allValid()) {
      this.state.auth.password=sha256(this.state.auth.password);
      handlePromise(login(this.state.auth.toJson()));
    }
    else {
      this.setState({});
      this.authFormHandler.validator.showMessages();
    }
  }


  render() {
    let { auth } = this.state;
    let { errors,isFetching } = this.props;
    return (<div className="card">
      <div className="card-body">
        <form onSubmit={event => {event.preventDefault();}}>
        <LoginForm model={auth} onModelChange={this.authFormHandler.onModelChange} validator={this.authFormHandler.validator} errors={errors} />
        <button type="submit" className="btn btn-dark btn-block"  onClick={this.submitLoginForm}>
        <i className="fa fa-lock p-1" style={{float:"left"}}/>
        { isFetching && <i className="fa fa-spinner fa-spin p-2"/>}LOGIN</button>
        </form>
      </div>
      <div className="card-body">
        <NavLink to="/auth/forgot_password" className="btn btn-outline-info">ForgotPassword</NavLink>
      </div>
    </div>);
  }
}


export default restCallController(LoginFormComponent);